import request from '../utils/request'

export function getStatistics(data) {
    return request({
        url: '/api/statistic',
        method: 'get',
        params: data
    })
}

export function getStatPhoto(data) {
  return request({
      url: '/api/stat-photo',
      method: 'get',
      params: data
  })
}