import request from '../utils/request'

export function getSchools(data) {
    return request({
        url: '/api/school',
        method: 'get',
        params: data
    })
}

export function getSchoolsDistrict(id) {
  return request({
      url: '/api/school-district/'+id,
      method: 'get',
  })
}

export function getSchoolsId(id) {
    return request({
        url: '/api/school/'+id,
        method: 'get',
    })
}

export function postSchools(data) {
    return request({
        url: '/api/school',
        method: 'post',
        data
    })
}

export function putSchools(data, id) {
    return request({
        url: '/api/school/'+id,
        method: 'put',
        data
    })
}

export function deleteSchools(id) {
    return request({
        url: '/api/school/'+id,
        method: 'delete'
    })
}
