<template>
<v-container fluid class="dashboard_container">
  <v-row>
    <v-container fluid grid-list-lg>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card>
            <v-card-title>
              <v-row>
                <v-col cols="2" class="text-center vertical_align">
                  <v-autocomplete label="Бўлимни танланг" :items="question_type" v-model="type_value" clearable></v-autocomplete>
                </v-col>
                <v-col cols="4" class="text-center vertical_align" v-if="type_value=='Барчаси'||type_value==null">
                  <v-autocomplete label="Саволни танланг" :items="questions" v-model="search_data.q_id" item-text="text" item-value="id" clearable></v-autocomplete>
                </v-col>
                <v-col cols="4" class="text-center vertical_align" v-if="type_value=='Куз қиш ҳолати'">
                  <v-autocomplete label="Саволни танланг" :items="fall_questions" v-model="search_data.q_id" item-text="text" item-value="id" clearable></v-autocomplete>
                </v-col>
                 <v-col cols="4" class="text-center vertical_align" v-if="type_value=='Интернет ҳолати'">
                  <v-autocomplete label="Саволни танланг" :items="internet_questions" v-model="search_data.q_id" item-text="text" item-value="id" clearable></v-autocomplete>
                </v-col>
                <v-col cols="2">
                  <v-autocomplete label="Шаҳарни танланг" :items="cities" v-model="search_data.c_id" item-text="name" item-value="id" clearable></v-autocomplete>
                </v-col>
                <v-col cols="2">
                  <v-autocomplete label="Туманни танланг" :items="districts" v-model="search_data.d_id" item-text="name" item-value="id" clearable></v-autocomplete>
                </v-col>
                <v-col cols="2">
                  <v-autocomplete label="Мактабни танланг" :items="schools" v-model="search_data.s_id" item-text="name" item-value="id" clearable></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-title>

            <v-card-text >
              <v-divider></v-divider>
              <v-row v-for="(val, i) in selected_question" :key="i" class="pt-5">
                <v-col cols="4" >
                  <v-card class="mx-auto" tile>
                    <v-list dense>
                      <v-subheader class="text-center pb-10" style="margin-bottom:2rem;">
                        <h2>
                          {{val.text}}
                        </h2>
                      </v-subheader>
                      <v-list-item-group color="primary">
                        <v-list-item v-for="(h1,i1) in val.AnswerOptions" :key="i1">
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-progress-linear :value="calcProcent(val.id, h1.id)" height="15" :color="colors[i1]">
                                <strong>{{ calcProcent(val.id, h1.id) }}% ({{calcCount(val.id, h1.id)}})</strong>
                              </v-progress-linear>
                            </v-list-item-title>
                            <v-list-item-subtitle>{{h1.text}}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card>
                </v-col>
                <v-col cols="8">
                  <v-data-table :items="rows[i]" :items-per-page="5">
                    <template v-slot:header>
                      <thead>
                        <tr>
                          <th rowspan="2" v-if="!search_data.c_id" class="text-center parent-header table-border-style">Шаҳар</th>
                          <th rowspan="2" v-if="!!search_data.c_id && !search_data.d_id && districts.length" class="text-center parent-header table-border-style">
                            <v-btn flat icon color="indigo" @click="f_back('c_id')"><span class="mdi mdi-arrow-left"></span></v-btn>Туман
                          </th>
                          <th rowspan="2" v-if=" !!search_data.c_id  && search_data.d_id && schools.length" class="text-center parent-header table-border-style">
                            <v-btn flat icon color="indigo" @click="f_back('d_id')"><span class="mdi mdi-arrow-left"></span></v-btn> Мактаб
                          </th>
                          <th rowspan="2" class="text-center parent-header table-border-style">
                            Жавоблар сони
                          </th>
                          <th rowspan="2" v-if=" !!search_data.c_id  && search_data.d_id && schools.length" class="text-center parent-header table-border-style">
                            Фото жавоблар
                          </th>
                          <th :rowspan="val.AnswerOptions?1:2" :colspan="val.AnswerOptions?val.AnswerOptions.length:1" class="text-center parent-header table-border-style">
                            {{ val.text }}
                          </th>
                        </tr>
                        <tr>
                          <th v-for="(h1,i1) in val.AnswerOptions" :key="i1" class="text-center child-header table-border-style">
                            {{ h1.text }}
                          </th>
                        </tr>
                      </thead>
                    </template>
                    <template #item="props">
                      <!--<template slot="items" slot-scope="props">-->
                      <tr :class="props.index < 3 ? 'red lighten-4': ''">
                        <td class="table-border-style cursor_table" @click="selectName( props.item)">{{ props.item.name }}</td>
                        <td class="table-border-style">{{ (props.item.stat) ? props.item.stat.count: 0 }}</td>
                        <td class="table-border-style" v-if=" !!search_data.c_id  && search_data.d_id && schools.length" @click="finImages(props.item)">
                          {{ findFoto(props.item, i)}}
                        </td>
                        <td class="table-border-style" v-for="(h1,i1) in val.AnswerOptions" :key="i1">{{ (props.item.stat.count) ? findAnswer(props.item.stat, h1) : 0}} </td>
                      </tr>
                    </template>
                    <template v-slot:body.append>
                      <tr class="grey lighten-4" v-if="footer_row[i]">
                        <td class="table-border-style "><b>Жами:</b></td>
                        <td class="table-border-style">
                          {{footer_row[i]}}
                        </td>
                        <td class="table-border-style" v-if=" !!search_data.c_id  && search_data.d_id && schools.length">
                         
                        </td>
                        <td class="table-border-style" v-for="(h1,i1) in val.AnswerOptions" :key="i1">
                          {{calcCount(val.id, h1.id)}} </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <img-viewer ref="viewer" />
        </v-flex>
      </v-layout>
    </v-container>
  </v-row>
</v-container>
</template>

<script>
import {
  getCities
} from '../../api/cities'
import {
  getDistrictsCity
} from '../../api/districts'
import {
  getSchoolsDistrict
} from '../../api/school'
import {
  getQuestions
} from '../../api/question'
import {
  getStatistics,
  getStatPhoto
} from '../../api/statistic'
import Vue from 'vue'
import ImgViewer from "../../components/ImgViewer";

export default {
  components: {
    ImgViewer
  },
  data() {
    return {
      cities: [],
      districts: [],
      schools: [],
      question_type: ['Барчаси','Куз қиш ҳолати','Интернет ҳолати'],
      type_value: 'Барчаси',
      questions: [],
      internet_questions: [],
      fall_questions: [],
      photos: [],
      search_data: {},
      statData: [],
      headers: [],
      rows: [],
      footer_row: [],
      images: [],
      inf_variable: null,
      photos_count: [],
      selected_question: [],
      colors: ['blue', 'success', 'amber', 'teal', 'orange', 'red', 'cyan', 'blue-grey']
    }
  },
  watch: {
    'search_data.c_id': function (val) {
      if (val) {
        this.fetchDataDistrict(val)
        this.fetchDataStatistic({
          'c_id': val
        })
      } else {
        this.districts = []
        this.schools = []
        this.fetchDataStatistic()
      }
    },
    'search_data.d_id': function (val) {
      if (val) {
        this.fetchDataSchool(val)
        this.fetchDataStatistic({
          'd_id': val
        })
      } else {
        this.schools = []
        this.fetchDataStatistic({
          'c_id': this.search_data.c_id
        })
      }
    },
    'search_data.s_id': function (val) {
      if (val) {
        this.fetchDataStatistic({
          's_id': val
        })
      } else {
        this.fetchDataStatistic({
          'd_id': this.search_data.d_id
        })
      }
    },
    'type_value' : function(){
        if(this.type_value=='Интернет ҳолати'){
          this.selected_question = this.internet_questions
        }
      else  if(this.type_value=='Куз қиш ҳолати'){
          this.selected_question = this.fall_questions
        }
        else if(this.type_value=='Барчаси'||this.type_value==null) {
        this.selected_question = this.questions
        }
    },
    'search_data.q_id': function (val) {
      this.selected_question = []
      if (val) {
          for (const q of this.questions) {
          if (val * 1 === q.id * 1) this.selected_question.push(q)}
      } 
      else {
        if(this.type_value=='Интернет ҳолати'){
          this.selected_question = this.internet_questions
        }
      else  if(this.type_value=='Куз қиш ҳолати'){
          this.selected_question = this.fall_questions
        }
        else {
        this.selected_question = this.questions
        }
      }
      var dt = {}
      if (this.search_data.c_id) dt.c_id = this.search_data.c_id
      if (this.search_data.d_id) dt.d_id = this.search_data.d_id
      if (this.search_data.s_id) dt.s_id = this.search_data.s_id
      this.fetchDataStatistic(dt)
    }
  },
  mounted() {
    this.fetchDataCity()
    this.fetchDataQuestion()
    this.fetchDataStatistic()
    this.fetchStatPhoto()
  },
  methods: {
    fetchDataStatistic(val = {}) {
      let variable = Object.getOwnPropertyNames(val)
      getStatistics(val).then(res => {
        this.statData = res.data
        this.inf_variable = variable[0]
        switch (variable[0]) {
          case 'c_id':
            this.rows = []
            for (let i = 0; i < this.selected_question.length; i++) {
              this.rows[i] = []
              this.footer_row[i] = 0
              let count = 0
              for (const dist of this.districts) {
                let dt = {
                  name: dist.name,
                  d_id: dist.id,
                  stat: {
                    count: 0
                  },
                }
                for (const stat of res.data) {
                  if (stat.d_id === dist.id && stat["Question.id"] * 1 === this.selected_question[i]['id'] * 1) {
                    dt.stat = stat
                    count += stat.count * 1
                  }
                }
                this.rows[i].push(dt)
                this.footer_row[i] = count
              }
              this.rows[i] = this.rows[i].slice().sort(function (a, b) {
                return b.stat.count * 1 - a.stat.count * 1;
              });
            }
            break
          case 'd_id':
            this.rows = []
            // this.photos_count = []
            for (let i = 0; i < this.selected_question.length; i++) {
              this.rows[i] = []
              this.footer_row[i] = 0
              let count = 0
              for (const dist of this.schools) {
                let dt = {
                  name: dist.name,
                  s_id: dist.id,
                  stat: {
                    count: 0
                  }
                }
                for (const stat of res.data) {
                  if (stat.s_id === dist.id && stat["Question.id"] * 1 === this.selected_question[i]['id'] * 1) {
                    dt.stat = stat
                    count += stat.count * 1
                  }
                }
                this.rows[i].push(dt)
                this.footer_row[i] = count
              }

              this.rows[i] = this.rows[i].slice().sort(function (a, b) {
                return b.stat.count * 1 - a.stat.count * 1;
              });
            }
            break
          case 's_id':
            this.rows = []
            for (let i = 0; i < this.selected_question.length; i++) {
              this.rows[i] = []
              this.footer_row[i] = 0
              let count = 0
              let dt = {
                stat: {
                  count: 0
                }
              }
              for (const sch of this.schools) {
                if (val.s_id * 1 === sch.id * 1) dt.name = sch.name
              }
              for (const stat of res.data) {
                dt.name = stat['School.name']
                if (stat["Question.id"] * 1 === this.selected_question[i]['id'] * 1) {
                  dt.stat = stat
                  count += stat.count * 1
                }
              }
              this.rows[i].push(dt)
              this.footer_row[i] = count
            }
            break
          default:
            this.rows = []
            for (let i = 0; i < this.selected_question.length; i++) {
              this.rows[i] = []
              this.footer_row[i] = 0
              let count = 0
              for (const dist of this.cities) {
                let dt = {
                  name: dist.name,
                  c_id: dist.id,
                  stat: {
                    count: 0
                  }
                }
                for (const stat of res.data) {
                  if (stat.c_id * 1 === dist.id * 1 && stat["Question.id"] * 1 === this.selected_question[i]['id'] * 1) {
                    dt.stat = stat
                    count += stat.count * 1
                  }
                }
                this.rows[i].push(dt)
                this.footer_row[i] = count
              }
              this.rows[i] = this.rows[i].slice().sort(function (a, b) {
                count += a.stat.count * 1
                a.total_count = count
                return b.stat.count - a.stat.count;
              });
            }
            break
        }
      })
    },
    fetchDataDistrict(id) {
      getDistrictsCity(id).then(res => {
        this.districts = res.data
      }).catch(error => {
        console.log(error)
      })
    },
    fetchDataQuestion() {
      getQuestions().then(res => {
        this.questions = res.data
        this.selected_question = res.data
        for (const q of this.questions) {
          if(q.id>5) this.internet_questions.push(q)
          if(q.id<=5) this.fall_questions.push(q)
      }
      }).catch(error => {
        console.log(error)
      })
      
    },
    fetchDataSchool(id) {
      getSchoolsDistrict(id).then(res => {
        this.schools = res.data
      }).catch(error => {
        console.log(error)
      })
    },
    fetchDataCity() {
      getCities().then(res => {
        this.cities = res.data
      }).catch(error => {
        console.log(error)
      })
    },
    fetchStatPhoto() {
      getStatPhoto().then(res => {
        this.photos = res.data
      }).catch(error => {
        console.log(error)
      })
    },
    findAnswer(val, answerOptions) {
      for (const a_count of val['answer']) {
        if (a_count['a_id'] * 1 === answerOptions['id'] * 1) {
          return a_count.count
        }
      }
      return 0
    },
    calcProcent(q_id, a_id) {
      let q_count = 0
      let a_count = 0
      for (const val of this.statData) {
        if (q_id === val['Question.id']) q_count += val.count * 1
        for (const ans of val['answer']) {
          if (a_id === ans['a_id']) a_count += ans.count * 1
        }
      }
      let calc = 0
      if (q_count) {
        calc = (a_count / q_count) * 100
        return Math.ceil(calc)
      }
      return 0
    },
    calcCount(q_id, a_id) {
      let a_count = 0
      for (const val of this.statData) {
        for (const ans of val['answer']) {
          if (a_id === ans['a_id']) a_count += ans.count * 1
        }
      }
      return a_count
    },
    selectName(item_id) {
      if (item_id.c_id) Vue.set(this.search_data, 'c_id', item_id.c_id)
      if (item_id.d_id) Vue.set(this.search_data, 'd_id', item_id.d_id)
      if (item_id.s_id) Vue.set(this.search_data, 's_id', item_id.s_id)
    },
    f_back(val) {
      this.search_data[val] = undefined
    },
    findFoto(item, i) {
      if (!this.photos_count.length) this.photos_count[i] = 0
      for (const val of this.photos) {
        if (item.s_id * 1 == val.s_id * 1 && item['stat']['Question.id'] * 1 == val.question_id * 1) {
          this.photos_count[i] += val.count
          return val.count
        }
      }
      return 0
    },
    finImages(item) {
      this.images = []
      for (const val of this.photos) {
        if (item.s_id * 1 == val.s_id * 1 && item['stat']['Question.id'] * 1 == val.question_id * 1) {
          this.images = val.photos
          let img = []
          for (const item of this.images) {
            img.push({
              thumbnail: '/' + item.url,
              source: '/' + item.url
              // thumbnail: `https://picsum.photos/id/35/300/200`,
              // source: `https://picsum.photos/id/35/600/400`

            });
          }
          this.$refs.viewer.show(
            img,
            0
          );
        }
      }
    }
  }
}
</script>

<style lang="scss">
.container.fill-height {
  // align-items: flex-start !important;
}

.dashboard_icon {
  font-size: 4.25rem !important;
  display: inline-block !important;
  vertical-align: middle !important;
  line-height: normal !important;
}

.vertical_align {
  display: inline-block !important;
  vertical-align: middle !important;
  line-height: normal !important;
}

.dash_card {
  background: linear-gradient(to right, rgb(30, 136, 229), rgb(33, 193, 214));
  padding: 15px;
  border-radius: 6px !important;

  .headline {
    font-size: 1.2em;
    margin: 5% auto;
  }

  .number {
    line-height: 2;
    font-size: 2em;
  }
}

.table-border-style {
  border: 1px solid #ccc !important;
}

.cursor_table {
  cursor: pointer;
}
</style>
